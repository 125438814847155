/*eslint no-magic-numbers: "warn"*/
/*eslint no-undef: "warn"*/

'use strict';

var $ = require('jquery');
var _ = require('underscore');
var GoogleMapsLoader = require('google-maps');

var GoogleMap = {
  init: function() {
    if($('.js-map').length) {
      var map,
        gymMarker,
        mapContainer = $('.js-map'),
        mapWidth = mapContainer.outerWidth(),
        panX = 0,
        markerImage;

      GoogleMapsLoader.KEY = 'AIzaSyDdLh_nPRZdpCCZ__kvw7d-4sahRvlWJ-Y';

      GoogleMapsLoader.load(function(google) {
        markerImage = {
          url: themeUrl + '/dist/assets/images/bg-marker-r.png',
          scaledSize: new google.maps.Size(70, 70),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(30, 55)
        };

        map = new google.maps.Map(document.getElementById('google-map'), {
          center: {lat: MAP_LAT, lng: MAP_LNG},
          zoom: 16,
          disableDefaultUI: true,
          styles: [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":"0"},{"color":"#738085"},{"lightness":"0"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"off"},{"color":"#000000"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#0d1e25"},{"lightness":"0"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#0d1e25"},{"lightness":"0"},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#0d1e25"},{"lightness":"0"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#0d1e25"},{"lightness":"3"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#15252c"},{"lightness":"3"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#15252c"},{"lightness":"8"},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#15252c"},{"lightness":"2"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#15252c"},{"lightness":"2"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#15252c"},{"lightness":"3"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#0d1e25"},{"lightness":"18"}]}]
        });

        panX = (mapWidth / 3) / 2;
        map.panBy(-panX, 0);

        gymMarker = new google.maps.Marker({
          position: {lat: MAP_LAT, lng: MAP_LNG},
          map: map,
          icon: markerImage,
          optimized: false
        });

        $(window).on('resize', _.debounce(function () {
          mapWidth = mapContainer.outerWidth();
          panX = (mapWidth / 3) / 2;
          map.setCenter(new google.maps.LatLng(MAP_LAT, MAP_LNG));
          map.panBy(-panX, 0);
        }, 250));
      });
    }
  },

};

module.exports = GoogleMap;
