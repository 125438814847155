/*
  Project: Pakerhala
  Author: ciachamy.pl
 */

var gallery = require('./gallery.js');
var navigation = require('./navigation.js');
var GoogleMap = require('./map.js');
window.GoogleMap = GoogleMap;

gallery();
navigation();
GoogleMap.init();
