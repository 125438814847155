'use strict';

var $ = require('jquery');

var navigation = function () {
  $('.js-menu-toggle').on('click', function () {
    $(this).toggleClass('is-open');
    $('.js-menu-container').toggleClass('is-open');
  });
};

module.exports = navigation;
