'use strict';

var $ = require('jquery');
var magnificPopup = require('magnific-popup');
var jQueryBridget = require('jquery-bridget');
var Masonry = require('masonry-layout');
var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin($);
jQueryBridget('masonry', Masonry, $);

var gallery = function () {
  $.extend(true, $.magnificPopup.defaults, {
    tClose: 'Zamknij (Esc)',
    tLoading: 'Ładowanie...',
    gallery: {
      tPrev: 'Wstecz (Lewa strzałka)',
      tNext: 'Następny (Prawa strzałka)',
      tCounter: '%curr% z %total%'
    },
    image: {
      tError: '<a href="%url%">Obraz</a> nie może być załadowany.'
    },
    ajax: {
      tError: '<a href="%url%">Błąd zapytania</a>.'
    }
  });

  if ($('.c-gallery').length) {
    $('.c-gallery').imagesLoaded(function () {
      var msnry = $('.c-gallery__list').masonry({
        itemSelector: '.c-gallery__item',
        gutter: '.c-gallery__gutter',
        percentPosition: true
      });
    });

    $('.c-gallery__list').each(function () {
      $(this).magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
          enabled: true
        },
        removalDelay: 300,
        mainClass: 'mfp-fade',
      });
    });
  }
};

module.exports = gallery;
